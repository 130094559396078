
import { defineComponent } from "vue";
import SalesOppsDataTableWrapper from "@/components/Sales/SalesOppsDataWrapper.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import Footer from "@/components/Footer.vue";
import OpportunitiesTabView from "@/components/UI/OpportunitiesTabView.vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "SalesOpps",
  components: {
    Button,
    SalesOppsDataTableWrapper,
    Card,
    Footer,
    OpportunitiesTabView,
  },
  computed: {
    ...mapGetters({
      getRangeEnd: "saleOpps/getRangeEnd",
      getCurrPage: "saleOpps/getCurrPage",
      getFirstRow: "saleOpps/getFirstRow",
      getCustomer: "customerInquiry/getCustomer",
      getSalesOppsIds: "customerInquiry/getSalesOppsIds",
      getNewOppsCount: "salesInquiry/getNewOppsCount",
      getNewCustomerOppsCount: "customerInquiry/getNewCustomerOppsCount",
      getActiveTab: "customerInquiry/getActiveTab",
      getActiveOppTab: "customerInquiry/getActiveOppTab",
      getActiveIndexCustomer: "customerInquiry/getActiveIndexCustomer",
      getActiveCustomerOpenSalesOpps:
        "customerInquiry/getActiveCustomerOpenSalesOpps",
    }),
    getCustomerFields(): any {
      const { cust_id, name, contact_id_items } = this.getCustomer(this.index);
      return {
        cust_id,
        name,
        contact_id_items,
      };
    },
  },
  props: {
    index: Number,
    currentView: String,
  },
  created() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
    if (this.$route.fullPath.includes("/customers")) {
      this.handleActiveOpportunityTab();
    }
  },

  updated() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
  },

  data() {
    return {
      order: false,
      isEditing: false as boolean,
      id: "",
      isResultsListView: true,
    };
  },
  methods: {
    ...mapActions({
      setFirstRow: "saleOpps/setFirstRow",
      pushSaleOppsId: "customerInquiry/pushSaleOppsId",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      removeSalesOpp: "customerInquiry/removeSalesOpp",
      addCustomerSalesOpp: "customerInquiry/addCustomerSalesOpp",
      addOpenedSalesOppCustomerInquiry:
        "customerInquiry/addOpenedSalesOppCustomerInquiry",
    }),
    handleActiveOpportunityTab() {
      const activeOpportunityTab = this.getActiveTab;
      const opportunites = this.getActiveCustomerOpenSalesOpps;

      const oppId =
        opportunites.length > 0 && opportunites[activeOpportunityTab - 1]
          ? opportunites[activeOpportunityTab - 1].record.id
          : "";

      if (oppId !== "" && this.$route.fullPath.includes("customer")) {
        this.$router.push(
          `/customers/${this.getActiveIndexCustomer.customer.cust_id}/sales/opportunities/${oppId}`,
        );
      } else {
        this.$router.push("/customers/sales/opportunities");
      }
    },
    switchResultView(isList: boolean) {
      this.isResultsListView = isList;
    },
    printExportData() {
      (this.$refs as any).salesOppsTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOppsTable.clearAllFiltersAndFetch();
    },
    handleSalesOpp(editing: boolean, data = null as any) {
      const customer = this.getCustomer(this.index);
      const cust_id = customer.cust_id;
      if (editing && data) {
        data = {
          oldRecord: JSON.parse(JSON.stringify(data)),
          saleType: "opportunities",
          ...data,
        };

        this.addOpenedSalesOrder(data);
        this.$router.push(
          `/customers/${cust_id}/sales/opportunities/${data.id}`,
        );
      } else {
        const id = this.$route.fullPath.includes("customer")
          ? (this.getNewCustomerOppsCount ?? 0) + 1
          : (this.getNewOppsCount ?? 0) + 1;

        const count =
          this.getNewCustomerOppsCount === undefined
            ? 1
            : this.getNewCustomerOppsCount + 1;

        const itemToEdit = {
          id: `New Opp-${count}`,
          saleType: "opportunities",
          oldRecord: {},
        };
        this.addOpenedSalesOppCustomerInquiry(itemToEdit);
        this.$router.push(
          `/customers/${cust_id}/sales/opportunities/New Opp-${id}`,
        );
      }
    },
  },
});
